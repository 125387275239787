<template>
	<div class="patent">
		<section class="patent--intro" v-if="Object.keys(patent).length">
			<div class="title">
				<h3>{{patent[0].title.rendered}}</h3>
			</div>
			<div class="image" v-if="patent[0]" :data-slug="patent[0].slug">
				<ImageById :media_id="patent[0]['toolset-meta']['brevetti']['immagine-iniziale']['attachment_id']" />
			</div>
			<div class="patent--logo" v-if="patent[0]">
				<ImageById :media_id="patent[0]['toolset-meta']['brevetti']['logo-small']['attachment_id']" />
				<div class="divider"></div>
				<span v-for="(text, index) in patent[0]['toolset-meta']['brevetti']['descrizione-small']['raw'].split(/\r?\n/)" :key="index">{{text}}</span>
			</div>
			<div class="patent--description">
				<h3>
					<span v-for="(text, index) in patent[0]['toolset-meta']['brevetti']['sottotitolo']['raw'].split(/\r?\n/)" :key="index">{{text}}</span>
				</h3>
				<!-- <div class="divider" v-if="patent[0]['toolset-meta']['brevetti']['descrizione-big']['raw']"></div>
				<p v-if="patent[0]['toolset-meta']['brevetti']['descrizione-big']['raw']">{{patent[0]['toolset-meta']['brevetti']['descrizione-big']['raw']}}</p> -->
			</div>
		</section>
		<section class="patent--gallery" v-if="Object.keys(patent).length">
			<CarouselTechnology :gallery="gallery" />
		</section>
		<section class="patent--next-page" v-if="Object.keys(patent).length">
			<div class="next--wrapper">
				<router-link :to="$i18nRoute({ name: 'Patents' })" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
					<div class="title">
						<h3>{{$t('brevetti.indietro')}}</h3>
					</div>
				</router-link>
			</div>
		</section>
	</div>
</template>

<script>
import axios from 'axios';
import ImageById from '@/components/ImageById';
import CarouselTechnology from '@/components/CarouselTechnology';
export default {
	data() {
		return {
			slug: this.$route.params.slug,
			patent: [],
			gallery: [],
			current_language: this.$route.params.locale,
			headTitle: ''
		}
	},
	components: {
		ImageById,
		CarouselTechnology
	},
	metaInfo() {
		return{
			title: this.headTitle,
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		getPatent(reloadGallery){
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/brevetto/?slug=' + this.slug + '&lang=' + this.current_language)
				.then(response => {
					this.patent = response.data;
					this.headTitle = this.patent[0].title.rendered
					if(reloadGallery){
						this.gallery = []
						this.patent[0]['toolset-meta']['brevetti']['gallery']['repeatable'].forEach(element => {
							this.gallery.push(element.attachment_id)
						});
					}
					
				});
		}
	},
	beforeMount() {
		this.getPatent(true);
	},
	watch: {
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.getPatent(false)
		}
	},
}
</script>

<style lang="scss">
.patent {
	position: relative;
	width: 100%;
	height: auto;
	.patent--intro {
		position: relative;
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding-top: 10vh;
		box-sizing: border-box;
		z-index: 3;
		@include mobile {
			padding-top: 60px;
			height: auto
		}
		.title {
			position: relative;
			width: 65vw;
			display: flex;
			flex-direction: column;
			@include mobile {
				width: 100%;
				padding: 10vh 0 5vh 0;
				order: 1;
			}
			h3 {
				font-family: 'Suisse Int';
				font-weight: 500;
				color: $black;
				font-size: 10vw;
				line-height: 10vw;
				text-transform: uppercase;
				margin: 0;
				&:first-of-type {
					align-self: flex-start;
				}
				&:nth-of-type(2) {
					align-self: flex-end;
				}
				&:last-of-type {
					align-self: center;
				}
				@include mobile {
					font-size: 15vw;
					line-height: 15vw;
				}
			}
		}
		.image {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 35vw;
			height: auto;
			transform: translate(-50%, -50%);
			z-index: 5;
			@include mobile {
				position: relative;
				top: auto;
				left: auto;
				order: 3;
				width: 100%;
				transform: translate(0, 0);
				padding: 0 15vw;
				box-sizing: border-box;
			}
			img {
				width: 100%;
				height: auto;
				display: block;
			}
			&[data-slug="wcolor"]{
				top: 40%;
			}
		}
		.patent--logo {
			position: absolute;
			left: 15%;
			top: 60%;
			transform: translate(-50%, -50%);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			z-index: 6;
			@include mobile {
				position: relative;
				width: 100%;
				left: auto;
				top: auto;
				transform: translate(0, 0);
				order: 2;
				align-items: center;
				padding: 0 15vw;
				box-sizing: border-box;
				margin-bottom: 5vh;
			}
			img {
				width: 120px;
				height: auto;
				display: block;
			}
			.divider {
				position: relative;
				width: 100%;
				height: 1px;
				background-color: $black;
				margin: 2.5vh 0;
				@include mobile {
					opacity: 0.3;
				}
			}
			span {
				margin: 0;
				text-transform: uppercase;
				font-family: 'Suisse Int';
				font-size: 14px;
				line-height: 14px;
				font-weight: 400;
				color: $black;
			}
		}
		.patent--description {
			position: relative;
			width: 30vw;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-left: 50vw;
			z-index: 4;
			@include mobile {
				order: 4;
				width: 100%;
				box-sizing: border-box;
				padding: 0 5vw;
				margin-top: 10vh;
			}
			h3 {
				display: flex;
				flex-direction: column;
				margin: 0;
				@include mobile {
					margin-bottom: 5vh;
				}
				span {
					text-transform: uppercase;
					font-family: 'Saol Display';
					font-size: 4vw;
					line-height: 3.5vw;
					font-weight: 400;
					color: $black;
					@include mobile {
						font-size: 8vw;
						line-height: 8vw;
					}
				}
			}
			.divider {
				position: relative;
				width: 100%;
				height: 1px;
				background-color: $black;
				margin: 5vh 0;
				@include mobile {
					display: none;
				}
			}
			p {
				color: $black;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Suisse Int';
				width: 80%;
				align-self: flex-start;
				margin: 0;
				@include mobile {
					width: 100%;
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
	}
	.patent--gallery {
		position: relative;
		width: 100%;
		height: auto;
		padding: 20vh 0;		
		@include mobile {
			padding: 10vh 0
		}
	}
	.patent--next-page {
		position: relative;
		width: 100%;
		height: 100vh;
		background-color: $red;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			height: 50vh;
		}
		.next--wrapper {
			position: relative;
			width: 100%;
			height: auto;
			a {
				position: relative;
				display: block;
				@include mobile {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
				.title {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					text-align: center;
					@include mobile {
						width: 80%;
						z-index: 6;
					}
					h3 {
						font-family: 'Suisse Int';
						font-weight: 500;
						color: $white;
						font-size: 10vw;
						line-height: 10vw;
						text-transform: uppercase;
						margin: 0;
						@include mobile {
							font-size: 15vw;
							line-height: 15vw;
						}
						span {
							display: flex;
							align-items: center;
						}
						svg {
							position: relative;
							height: 8vw;
							width: auto;
							display: inline-block;
							path {
								fill: $white;
							}
						}
						&.withSvg {
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
					}
				}
				.image {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 16vw;
					height: auto;
					transform: translate(-50%, -50%);
					img {
						width: 100%;
						height: auto;
						display: block;
					}
				}
			}
		}
	}
}
</style>